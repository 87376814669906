import * as React from "react";
import Layout from "../../../components/layout"

const IndexPage = () => (
  <Layout>
    <h1>HL2056</h1>
  </Layout>
)

export default IndexPage
